<template>
  <div>
    <div class="row  Integration-Main">
                <div class="col-12 col-md-3  Integration-left">
                   <div class=" bg-preload" style="width: 255px;
  height: 255px;">
                    <div class="into-preload"></div>
                   </div>

                   <div class=" mt-5">
                    <div class=" bg-preload" style="height:50px;">
                        <div class="into-preload"></div>
                    </div>
                    <div class=" ">
                         <div class="Connect bg-preload mt-3" style="height:50px;">
                        <div class="into-preload"></div>
                    </div>
                     <div class="Connect bg-preload mt-3" style="height:50px;">
                        <div class="into-preload"></div>
                    </div>
                    </div>
                   </div>
                </div>
                <div class="col-12  col-md-9   ">
                    <div class="bg-preload" style="height:50px;width:800px">
                        <div class="into-preload"></div>
                    </div>
                    <div class="bg-preload mt-4" style="height:50px;width:800px">
                        <div class="into-preload"></div>
                    </div>
                    <div class="bg-preload mt-4" style="height:200px;width:800px">
                        <div class="into-preload"></div>
                    </div>
                    <div class="bg-preload mt-4" style="height:50px;width:800px">
                        <div class="into-preload"></div>
                    </div>
                   
                </div>
           </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>