<template>
  <Modal :id="id" :title="title" :ref="id" size="md">
    <div class="mx-2">
        <div class="delteTitle">
          Are you sure want to delete this {{ item }}?
        </div>
        <div class="deleteSubtext mt-2">
          All data will be permanently deleted.
        </div>
        <div class="d-flex justify-content-center mt-3">
          <Button class="bg-danger" :isLoading="isLoading" @click="$emit('click')">Delete</Button>
        </div>
      </div>
  </Modal>
</template>

<script>
import Button from '../Layout/Button.vue'
import Modal from '../Layout/Modal.vue'
export default {
    props:{
        id:{
            type:String,
            default:'DeleteModal'
        },
        title:{
            type:String,
            default:'Delete Item'
        },
        item:{
            type:String,
            default:'item'
        },
        isLoading:Boolean
    },
components:{
    Modal,
    Button
},
methods:{
    open(){
        this.$refs[this.id].open()
    },
    close(){
        this.$refs[this.id].close()
    }
}
}
</script>

<style scoped>
.delteTitle {
  color: #18191C;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: capitalize;
}

.deleteSubtext {
  color: #73738D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 100%;
}

</style>