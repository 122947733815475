<template>
  <div>


<!-- Modal -->
<div class="modal  fade" style="backdrop-filter: blur(2px); background: #4d1b7e14;" :id="id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog "  :class="[`modal-${size}`,isCenter?'modal-dialog-centered':'']">
    <div class="modal-content " >
      <div class="container p-2 pb-4" >
          <div class="row ">
          <div class=" col-12 pt-3 pb-2 px-3">
            <div class="d-flex mx-2 justify-content-between">
            <slot name="title">
                <div class="modal-heading" v-html="title">
                
                </div>
            </slot>
               <button @click="close()" class="table-action-button"><i class="fa fa-times" aria-hidden="true"></i></button>
          </div>
          <hr v-if="isLine"  class="mb-0 mt-2">
          </div>
          </div>
       <slot></slot>
       </div>

    </div>
  </div>
</div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    name:'Modal',
    components:{
       
    },
    props:{
        id:{
            type:String,
            default:'modal-'+Math.random()
        },
        size:{
            type:String,
            default:'lg'
        },
         title:{
            type:String,
            default:'Quizell Modal'
        },
        isCenter:{
            type:Boolean,
            default:false,
            required:false,
        },
        isLine:{
             type:Boolean,
            default:true,
            required:false,
        },
        isStatic:{
            type:Boolean,
            default:false
        }
    },
data(){
    return{
      
    }
},
    methods:{
        open(){
            
      $(`#${this.id}`).modal({
  backdrop: this.isStatic ? 'static' : true,
  keyboard: false,
  show: true
});
        $(`#${this.id}`).modal('show')
    },
        close(){
            if(!this.isStatic)
        {
            $(`#${this.id}`).modal('hide');
        }
            this.$emit('close');
        },
      closeStatic(){
        $(`#${this.id}`).modal('hide');
      }
    },
    computed:{
        
    },
     updated(){
       
      }
}
</script>
<style>
.modal-backdrop{
    z-index: 200;
}
</style>
<style scoped>
.modal-heading {

    color: #1F1F28;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
}
.modal-content
{
    border-radius: 20px;
        overflow: hidden;
}

.modal-btn{
    background:#ffa201;
    color:#ffffff;
    border: none;
}
.modal-btn:hover{
    color:#ffffff;
}

.btn-profile-seeting
{
    border: 1px solid #ffa201;
    color: #ffa201;
}
.btn-profile-seeting:hover
{
    border: 1px solid #ffa201;
    color: #ffffff;
    background:#FFA201;
}


.TitleHead{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px;

color: #18191C;
}

.table-action-button{
  text-decoration: none;
  background:var(--white);
padding: 7px;
display:block;
gap: 4px;
line-height:0;
}
</style>
