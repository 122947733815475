<template>
  <div>
     <!-- Plans Start -->
          <div class="row  mt-2 pb-5 d-flex px-3 shopifyPlan" style="height:100%;">
              <div  class="col-12 col-sm-6 col-md-12 col-lg-4 col-xl-4 mx-lg-auto mb-3 Choose-plan-Main  h-100 mb-3 " v-for="(plan , index) in plans" :key="index" style="height:100%;">

                 <div class="card PricingCard w-100 py-3 px-2 h-100" style="min-height:400px;cursor: pointer;" @click="openPlan(plan)" :style="plan.title == 'Pro Plan' ? 'border: 1px solid #0FD452' : null">
                    <div class=" d-flex flex-column flex-grow-1">
                      <div class="PriceTopSection p-3" >

                          <div class="priceSection ">
                                <div class="d-flex align-items-center  mb-2 ">
                                    <p class="price  m-0">${{plan.price}}<span class="subText m-0 mt-3">/Monthly</span></p>
                                </div>

                                 <div class="title  justify-content-between align-items-start">
                              <p class="m-0">{{plan.title}}  <span v-if="plan.title == 'Pro Plan'" class="  most-popular ">Most popular</span></p>
                              

                          </div>

                          </div>


                      </div>

                       <div class="buttonSection ">
                                    <button  v-if="plan.isSelected" class="btn w-100 px-3 py-2 StartBtn" :style="getBtnStyles(plan)" >
                                        <span class="startBtn">Your current plan</span>
                                    </button>
                                    <button  v-else class="btn w-100 px-3 py-2 StartBtn" :style="getBtnStyles(plan)" @click="openPlan(plan)">
                                        <span class="startBtn">Start now </span>
                                    </button>

                          </div>

                      <div class="midSection p-3 ">
                          <div class="midFeatures  mb-2">
                              <!-- Engagements -->
                              <div class="">
                                  
                                   <div >
                                      
                                       <p class="m-0 mb-2" ><i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i> <span>{{plan.totalEngagements}} </span> Engagements monthly</p>
                                   </div>
                              </div>
                               <!-- Engagements End-->

                               <div class="">
                                  
                                   <p class="m-0 mb-2" ><i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i> <span class="" v-if="plan.totalQuiz <=1 ">{{plan.totalQuiz}} Quiz</span> <span class="" v-else>{{plan.totalQuiz}} Quizzes</span></p>
                               </div>
                               <!-- Quiz End-->
                               <div class="">
                                   <p class="m-0 mb-2" v-if="plan.totalQuestions !== 0 " ><i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i> Up to <span class=""> {{plan.totalQuestions}} </span>Questions</p>
                                   <p class="m-0 mb-2" v-else><i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i><span class="">Unlimited  </span></p>
                                  
                               </div>
                               <!-- Question End-->
                               <div class="">
                                   <p class="m-0 mb-2"  v-if="plan.totalProducts !== 0 "><i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i> Up to <span>{{plan.totalProducts}} </span>Products</p>
                                   <p class="m-0 mb-2" v-else ><i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i><span>Unlimited</span></p>
                                   
                               </div>
                               <!-- Products End-->

                          </div>

                          <div class=" midCheckFeatures">
                              <div v-for="(check , index) in planFeature" :key="index">
                                  <p class="m-0 mb-2"> <i class="fa fa-check-circle mr-2" :style="getTextColor(plan)" aria-hidden="true"></i> {{check.text}}</p>
                              </div>
                              
                          </div>


                      </div>

                      <div class="bottomSection px-3">
                  <p class="">
                    <span
                      class="mr-2"
                      style="font-weight: 900"
                      :style="getTextColor(plan)"
                      >{{ getIntegration(plan) }}</span
                    >
                    <span
                      style="
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                      "
                      >Integrations</span
                    >
                  </p>
                  <p class="">
                    <span
                      class="mr-2"
                      style="font-weight: 900"
                      :style="getTextColor(plan)"
                      >{{ getAnalytics(plan) }}</span
                    >
                    <span  style="
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                      ">Analytics</span>
                  </p>
                </div>

                       
                        </div>
                        
                  </div>
              </div>
          </div>
          <!-- Plans End -->
  </div>
</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex";
export default {
data(){
    return{
        plans:[],
        planFeature: [
        
        {
            "text": "Jump Logics"
        }
    ],
    
    }
},
methods:{
    getIntegration(plan) {
      let text = "";
      if (plan.name == "Basic Plan") {
        text = "Limited";
      } else {
        text = "Full";
      }

      return text;
    },
    getAnalytics(plan) {
      let text = "";
      if (plan.name == "Basic Plan") {
        text = "Basic";
      } else {
        text = "Full";
      }

      return text;
    },
    getPlans(){
         axios.get('/loadPlans').then(resp=>{
            
            let plans = resp.data.data
            const index = plans.findIndex(x=> x.id == 5)
            if(index > -1){
                plans.splice(index , 1)
                 this.plans = plans
            } else {
                     this.plans = resp.data.data;
            }
            // this.plans = resp.data.data;
        }).catch(error=>{
            console.log(error);
        });
    },
     getTitleStyles(plan){
            let styles =''
            
                styles = `background: ${plan.bg_color};
                 border-radius: 10px 10px 0px 0px;`
           
          

            return styles
        },
       getTextColor(plan){
            let style=` color:${plan.color};`
            return style
           
        }, getBtnStyles(plan){
    let style = `mix-blend-mode: normal;
border-radius: 24px;background:${plan.bg_color};color:#ffffff;opacity:0.8;`

return style
  },
 async  openPlan(plan){
     const user_access_token = localStorage.getItem("user_access_token")
          const api_Url = process.env.VUE_APP_API_BASE_URL
        if(user_access_token && plan.id){
             localStorage.setItem('selected_plan_id', plan.id);
          const url = `${api_Url}purchase/plan?planId=${plan.id}&token=${user_access_token}`
          window.open(url , '_blank')
        }
   
  },
},
mounted(){
    this.getPlans()
},
 computed:{
         ...mapGetters( ["getIsShopifyUser"]),
         IsShopifyUser(){
    return this.getIsShopifyUser
   }
    }
}
</script>

<style scoped>


/* Cards Styles */
.Choose-plan-Main   .PricingCard{
    background: #FFFFFF;
box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
border-radius: 20px;
}
.Choose-plan-Main  .PricingDiv .PriceTopSection{
    color:#ffffff;
     font-family: 'Poppins';
}
.Choose-plan-Main  .PriceTopSection  .priceSection .title p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px;
color: #1F1F28;
}

.Choose-plan-Main  .priceSection .price{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 46px;

color: #1F1F28;
}
.Choose-plan-Main  .priceSection .subText{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 26px;
color: #7C8290;
width: auto;
}

.Choose-plan-Main  .priceSection .priceText span{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 140%;
color: #7C8290;


}

.Choose-plan-Main .buttonSection a{
    background:transparent;
    border: 1.02182px solid #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
border-radius: 25px;
font-size:14px;
font-weight:500;
color:#ffffff;
}
.Choose-plan-Main .buttonSection a:hover{
    background:#ffffff;
color:#042542;
}

.Choose-plan-Main .midSection{
    font-family: 'Poppins';
    color: #535355;
}
.Choose-plan-Main .midTitle{
    font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
}

.Choose-plan-Main .midFeatures p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #7C8290;
}
.Choose-plan-Main .midFeatures p span{
font-weight: 700;
}
.Choose-plan-Main .midCheckFeatures p{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
color: #7C8290;

}

.Choose-plan-Main .endSection p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #1F1F28;

}

.Choose-plan-Main .startBtn{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;

text-align: center;

color: #FFFFFF;
}

.Pricing-Comp-Main .buttonSection .StartBtn:hover{
   opacity: 1 !important;
}

.Choose-plan-Main  .most-popular{
    background: #0FD452;
border-radius: 5.70473px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-align: center;

  padding: 8px 15px;
color: #FFFFFF;

}


.Choose-plan-Main .btn-group{
    background: #FFFFFF;
border: 1px solid #EAEAEA;
box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
border-radius: 100px;
color:#4d1b7e;
}
.Choose-plan-Main .btn-group p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 120%;
cursor: pointer;
text-align: center;

color: #4D1B7E;
}
.Choose-plan-Main .btn-group:hover{
    background: #4D1B7E0d;
}
.Choose-plan-Main .btn-group p:hover {
    background: #4D1B7E0d;
/* opacity: 0.05; */
border-radius: 0px 100px 100px 0px;
}
.Choose-plan-Main .btn-group .yearly:hover {
    background: #4D1B7E0d;
/* opacity: 0.05; */
border-radius: 100px 0px 0px 100px;
}

.Choose-plan-Main  .btn-group .selectedBtn{
background: #4D1B7E;
border-radius: 100px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 120%;
text-align: center;
color: #FFFFFF;

border-radius: 100px;
}
.Choose-plan-Main  .btn-group .selectedBtn:hover{
    color:#ffffff;
}
.Choose-plan-Main .topSection .title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 136%;
text-align: center;
color: #1F1F28;
}
.Choose-plan-Main .topSection .subText{
 font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 136%;
text-align: center;
color: #7C8290;
padding:0 25%;
}


@media   (min-width: 991px) and (max-width:1200px) {
 .shopifyPlan .PricingCard .priceSection .most-popular {
    /* display: block ; */
    font-size:12px;
     padding: 8px 4px;
  }
}
</style>